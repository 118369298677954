<template>
  <div>

    <!-- <h5>Carga de Archivos</h5> -->

    <table class="table table-info table-light mb-0">
      <tbody>
        <tr>
          <td class="table-dark text-center" colspan="3">
            Fechas de Carga de Archivos REPSE
          </td>
        </tr>
        <tr>
          <td class="table-dark">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'calendar-alt', }" class="icon btn-icon"/> Fecha Inicial para Cargar
          </td>
          <td>{{ datos.FechaLibCarga }}
            <a class="link" @click="msgBoxEditFechas" v-if="$editar(['Proveedores'])" v-b-popover.hover.bottom="'Editar fechas de carga'">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit', }" class="icon btn-icon" />
            </a>
        </td>
        </tr>
        <tr>
          <td class="table-dark">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'calendar-alt', }" class="icon btn-icon"/> Fecha Límite para Cargar
          </td>
          <td>{{ datos.FechaLimCarga }}
            <a class="link" @click="msgBoxEditFechas" v-if="$editar(['Proveedores'])" v-b-popover.hover.bottom="'Editar fechas de carga'">
              <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit', }" class="icon btn-icon" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal
      id="bv-modal-dias" size="sm" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$editar(['Proveedores'])">
      <template #modal-title>
        Dias de carga
      </template>
      <form class="form-site inner inner-sm" @submit.prevent="actualizarDias()">
        <div class="form-row">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('diaLibCarga')}">
            <fieldset>
              <legend>Dia (1-28) para liberar carga</legend>
              <input type="number" min="1" max="28" class="form-control text-center" placeholder="Dia Liberar" v-model="diaLibCarga">
              <span class="icon" :title="validation.firstError('diaLibCarga')" v-if="validation.hasError('diaLibCarga')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
        </div>
        <div class="form-row">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('diaLimCarga')}">
            <fieldset>
              <legend>Dia (1-28) Limite para cargar</legend>
              <input type="number" min="1" max="28" class="form-control text-center" placeholder="Dia Limite" v-model="diaLimCarga">
              <span class="icon" :title="validation.firstError('diaLimCarga')" v-if="validation.hasError('diaLimCarga')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Guardar
        </button>
      </form>
    </b-modal>

  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'DiasCarga',
  props: [
    'datos',
    'provId',
  ],
  data() {
    return {
      loading: false,
      diaLimCarga: '',
      diaLibCarga: '',
    };
  },
  validators: {
    diaLimCarga(value) { return Validator.value(value).required('Campo requerido').digit().between(1, 28, 'Solo se permiten dias del mes del 1 al 28'); },
    diaLibCarga(value) { return Validator.value(value).required('Campo requerido').digit().between(1, 28, 'Solo se permiten dias del mes del 1 al 28'); },
  },
  methods: {
    msgBoxEditFechas() {
      const msg = this.provId > 0 ? 'Si continua, se actualizarán las fechas de carga de archivos solamente de este Proveedor.' : 'Si continua, se actualizarán las fechas de carga de archivos para TODOS los proveedores REPSE.';
      this.$bvModal.msgBoxConfirm(msg, {
        title: 'Editar Fechas',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si Continuar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.openEditFechas();
        })
        .catch(() => {});
    },
    openEditFechas() {
      this.diaLimCarga = this.datos.DiaLimCarga;
      this.diaLibCarga = this.datos.DiaLibCarga;
      this.$bvModal.show('bv-modal-dias');
    },
    actualizarDias() {
      this.$validate().then((success) => {
        if (success) {
          this.loading = true;
          apiClient.put('/proveedores/dias-carga', {
            ProvId: this.provId,
            diaLibCarga: this.diaLibCarga,
            diaLimCarga: this.diaLimCarga,
          })
            .then(() => {
              this.$emit('submit');
              this.$bvModal.hide('bv-modal-dias');
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
